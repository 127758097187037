import React from 'react';

import { Box } from '@core';

import { Shape } from '@components';
import { ContentBlockSection } from '@containers';

import MoneyBackGuarantee from '@images/product-page/30-day-guarantee.png';

const Guarantee = ({ title, description, padding, shape, image, isReversed }) => (
  <Box {...padding}>
    <ContentBlockSection
      data={[
        {
          image: image || MoneyBackGuarantee,
          title: title,
          description: description,
          isReversed,
        },
      ]}
      shape={shape ? Shape[shape] : Shape.Blob4}
      shapeProps={{ top: { _: '5%', md: 0 } }}
      wrapperProps={{ pb: { _: 24, md: 0 } }}
    />
  </Box>
);

export default Guarantee;
